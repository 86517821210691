<template>
	<jf-layout>
		<template slot="head">
			<el-button size="small" type="primary" icon="el-icon-circle-plus" v-if="isAuth('platform-config:modulemanagement:save')" @click="add">新增</el-button>
			<el-button size="small" type="danger" icon="el-icon-delete" v-if="isAuth('platform-config:modulemanagement:delete')" :disabled="!selectList.length"
				@click="remove(null)">删除</el-button>
		</template>

		<el-table slot="body" border :data="list" v-loading="loading" @selection-change="selectChange">
			<el-table-column align="center" type="selection" width="50" />
			<el-table-column align="center" prop="sort" label="排序" />
			<el-table-column align="center" prop="moduleName" label="模块名称" />
			<el-table-column align="center" prop="icon" label="图标"></el-table-column>
			<el-table-column align="center" prop="moduleLink" label="模块链接" />
			<el-table-column align="center" prop="realName" label="创建人" />
			<el-table-column align="center" prop="" label="状态">
				<template slot-scope="{row}">
					<el-tag v-if="row.isShelves" size="small" type="success">上架</el-tag>
					<el-tag v-if="!row.isShelves" size="small" type="info">下架</el-tag>
				</template>
			</el-table-column>
			<el-table-column align="center" label="操作" width="290">
				<template slot-scope="{row}">
					<el-button size="mini" v-if="isAuth('platform-config:modulemenu:list')&&isAuth('platform-config:menu:info')" @click="goMenu(row)">菜单设置</el-button>
					<el-button size="mini" icon="el-icon-edit" v-if="isAuth('platform-config:modulemanagement:update')" @click="edit(row)">编辑</el-button>
					<el-button size="mini" v-if="!row.isShelves&&isAuth('platform-config:modulemanagement:update')" @click="changeState(row,1)">上架</el-button>
					<el-button size="mini" v-if="row.isShelves&&isAuth('platform-config:modulemanagement:update')" type="danger" @click="changeState(row,0)">下架</el-button>
					<el-button size="mini" v-if="isAuth('platform-config:modulemanagement:delete')" :disabled="Boolean(row.isShelves)" icon="el-icon-delete" @click="remove(row)"
						type="danger">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- <jf-page slot="foot" @change="getList"  :page.sync="params.page" :limit="params.limit" :total="total" /> -->
		<Form :show.sync="show" :row="row" @add="search" @edit="getList" />
	</jf-layout>
</template>

<script>
	import Form from "./form";
	export default {
		components: {
			Form
		},
		data() {
			return {
				show: false,
				params: {
					page: 1,
					limit: 99,
				},
				list: [],
				loading: false,
				row: {},
				total: 0,
				selectList: [],
			};
		},
		created() {
			this.getList();
		},
		methods: {
			// 跳转至菜单
			goMenu(row) {

				this.$router.push({
					path: "/setup-residentMenu",
					query: {
						id: row.id,
						name: row.moduleName
					}
				})
			},
			search() {
				this.params.page = 1;
				this.getList();
			},
			async getList() {
				this.loading = true;
				var res = await this.$get(
					"/platform-config/community/modulemanagement/page",
					this.params
				);
				this.loading = false;
				if (res && res.code === 0) {
					this.list = res.data.list;
					this.total = res.data.total;
				}
			},
			selectChange(list) {
				this.selectList = list;
			},
			add() {
				this.row = {};
				this.show = true;
			},
			edit(row) {
				this.row = row;
				this.show = true;
			},
			changeState(row, state) {
				var txt = state ? "上架" : "下架";
				this.$confirm(`确认将该模块${txt}？`, "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(async () => {
						var data = {
							...row,
						};
						data.isShelves = state
						var res = await this.$put("/platform-config/community/modulemanagement", data);
						if (res && res.code === 0) {
							this.$message.success("操作成功");
							this.getList();
						}
					})
					.catch(() => {});
			},
			remove(row) {
				this.$confirm("确认删除选择的数据", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(async () => {
						if (row) {
							var ids = [row.id];
						} else {
							var ids = this.selectList.map((item) => {
								return item.id;
							});
							console.log(ids);
						}
						var res = await this.$del(
							"/platform-config/community/modulemanagement",
							ids
						);

						if (res && res.code === 0) {
							this.$message({
								type: "success",
								message: "删除成功!",
							});
							this.getList();
						}
					})
					.catch(() => {});
			},
		},
	};
</script>

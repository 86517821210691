<template>
  <el-dialog v-dialogDrag :title="title" :visible.sync="visible" width="600px">
    <el-form label-width="100px" class="form" ref="form" :model="form" :rules="rules">
      <el-form-item label="模块名称" prop="moduleName">
        <el-input v-model="form.moduleName"></el-input>
      </el-form-item>
      <!-- <el-form-item label="简介" prop="">
        <el-input type="textarea" :rows="3"></el-input>
      </el-form-item> -->
      <el-form-item label="图标" prop="icon">
        <!-- <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false">
          <img v-if="form.icon" :src="form.icon" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload> -->
        <!-- <jf-ossUpload-img :value="form.icon" @result="getImg"></jf-ossUpload-img> -->
        <el-input v-model="form.icon" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="模块链接">
        <el-input v-model="form.moduleLink"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio v-model="form.isShelves" :label="1">上架</el-radio>
        <el-radio v-model="form.isShelves" :label="0">下架</el-radio>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="form.sort"></el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:show',false)">取 消</el-button>
      <el-button size="small" type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {},
      loading: false,
      editor: null,
      rules: {
        moduleName: [{ required: true, message: "模块名称必填" }],
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
    title() {
      var title = "新增应用模块";
      if (this.form.id) {
        title = "修改应用模块";
      }
      return title;
    },
  },
  watch: {
    row: {
      handler(val) {
        this.initForm(val);
      },
      deep: true,
    },
  },
  created() {
    this.initForm(this.row);
  },
  mounted() {},
  methods: {
    initForm(val) {
      // 判断是否拿到表单元素，重置表单
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      // 初始化form数据
      if (val.id) {
        this.form = { ...val };
      } else {
        this.form = {
          moduleName: "",
          isShelves: 1,
          icon: "",
          moduleLink: "",
        };
      }
    },
    getImg(e) {
      this.form.icon = e;
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          var data = { ...this.form };

          if (data.id) {
            var res = await this.$put(
              "/platform-config/community/modulemanagement",
              data
            );
          } else {
            var res = await this.$post(
              "/platform-config/community/modulemanagement",
              data
            );
          }
          this.loading = false;
          if (res && res.code === 0) {
            var str = data.id ? "修改成功" : "添加成功";
            var ev = data.id ? "edit" : "add";
            this.$message.success(str);
            this.$emit(ev);
            this.$emit("update:show", false);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 20px;
}
.editor {
  width: 100%;
  height: 350px;
}
.avatar-uploader /deep/ .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader /deep/ .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>